body {
  --primary: #005c89;
  --secondary: #005c89;
  --sjs-general-backcolor: white;
  /* --foreground: #161616;
    --background-dim: #fff;
    --primary-foreground: #ffffff;
    --background: #fff;
    --background-dim-light: #fff;
    --secondary: #1ab7fa;*/
  /* --sjs-general-backcolor: rgba(255, 255, 255, 0.1); */
  --sjs-general-backcolor-dark: rgba(52, 52, 52, 1);
  --sjs-general-backcolor-dim: #f3f5f7;
  /* --sjs-general-backcolor-dim-light: rgba(76, 57, 140, 1); */
  --sjs-general-backcolor-dim-dark: rgba(46, 46, 46, 1);
  --sjs-general-forecolor: black;
  --sjs-general-forecolor-light: #6f747b;
  --sjs-general-dim-forecolor: rgba(255, 255, 255, 0.79);
  --sjs-general-dim-forecolor-light: rgba(255, 255, 255, 0.45);
  --sjs-primary-backcolor: #005b89;
  --sjs-primary-backcolor-light: #005c8914;
  --sjs-primary-backcolor-dark: rgba(96, 92, 177, 1);
  --sjs-primary-forecolor: black;
  --sjs-primary-forecolor-light: rgba(255, 255, 255, 0.25);
  --sjs-base-unit: 8px;
  --sjs-corner-radius: 4px;
  --sjs-secondary: #005c89;
  --sjs-secondary-backcolor-light: rgba(255, 152, 20, 0.1);
  --sjs-secondary-backcolor-semi-light: rgba(255, 152, 20, 0.25);
  --sjs-secondary-forecolor: rgba(48, 48, 48, 1);
  --sjs-secondary-forecolor-light: rgba(48, 48, 48, 0.25);
  --sjs-shadow-small: 0px 0px 0px 1px rgba(255, 255, 255, 0.25);
  --sjs-shadow-medium: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  --sjs-shadow-large: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  --sjs-shadow-inner: 0px 0px 0px 1px rgba(255, 255, 255, 0.15);
  --sjs-border-light: rgba(255, 255, 255, 0.08);
  --sjs-border-default: #d6d6d6;
  --sjs-border-inside: rgba(255, 255, 255, 0.08);
  --sjs-special-red: rgba(254, 76, 108, 1);
  --sjs-special-red-light: rgba(254, 76, 108, 0.1);
  --sjs-special-red-forecolor: rgba(48, 48, 48, 1);
  --sjs-special-green: rgba(36, 197, 164, 1);
  --sjs-special-green-light: rgba(36, 197, 164, 0.1);
  --sjs-special-green-forecolor: rgba(48, 48, 48, 1);
  --sjs-special-blue: rgba(91, 151, 242, 1);
  --sjs-special-blue-light: rgba(91, 151, 242, 0.1);
  --sjs-special-blue-forecolor: rgba(48, 48, 48, 1);
  --sjs-special-yellow: rgba(255, 152, 20, 1);
  --sjs-special-yellow-light: rgba(255, 152, 20, 0.1);
  --sjs-special-yellow-forecolor: rgba(48, 48, 48, 1);
  --sjs-question-background: rgba(255, 255, 255, 1);
  --sjs-questionpanel-backcolor: #ffffff;
  --sjs-questionpanel-hovercolor: rgba(255, 255, 255, 0.464);
  --sjs-questionpanel-cornerRadius: 8px;
  /* --sjs-editor-background: rgba(76, 57, 140, 1); */
  --sjs-editorpanel-backcolor: rgba(91, 151, 242, 0.1);
  --sjs-editorpanel-hovercolor: #005c8914;
  --sjs-editorpanel-cornerRadius: 6px;
  --sjs-font-pagetitle-family: Open Sans;
  --sjs-font-pagetitle-weight: 700;
  --sjs-font-pagetitle-color: #0000008c;
  --sjs-font-pagetitle-size: 32px;
  --sjs-font-questiontitle-family: Open Sans;
  --sjs-font-questiontitle-weight: 600;
  --sjs-font-questiontitle-color: black;
  --sjs-font-questiontitle-size: 16px;
  --sjs-font-questiondescription-family: Open Sans;
  --sjs-font-questiondescription-weight: 400;
  --sjs-font-questiondescription-color: #000000;
  --sjs-font-questiondescription-size: 16px;
  --sjs-font-editorfont-family: Open Sans;
  --sjs-font-editorfont-weight: 400;
  --sjs-font-editorfont-color: black;
  --sjs-font-editorfont-size: 16px;
  --sjs-font-size: 16px;
  --font-size: 100;
}
/****************** Preview ******************/
.sd-body.sd-body--static {
  max-width: 90%;
}
.sv-list {
  background-color: white;
}
.sd-boolean {
  background-color: #005c8914;
}
.sd-file {
  min-height: 12vh;
}
.sd-file__choose-btn {
  color: black;
}
.sd-input {
  outline: 2px solid #bdc0c4;
  background-color: transparent;
}
.sd-input:active {
  outline: 2px solid #005c89;
  background-color: transparent;
}
.sv-string-viewer {
  color: #6f747b;
}
.sd-item--checked .sd-item__decorator {
  background-color: transparent;
  outline: 2px solid #005c89;
}
.sd-item__control:focus + .sd-item__decorator {
  outline: 2px solid #005c89;
}
.sd-item__control:focus + .sd-item__decorator::after {
  background-color: #005c89;
}
.sd-radio--checked .sd-radio__decorator::after {
  content: "";
  display: block;
  background-color: #005c89;
}
.sd-item__decorator {
  outline: 2px solid #6f747b;
  background-color: transparent;
}
.sd-body__navigation .sd-btn {
  outline: 2px solid #005c89;
  background-color: transparent;
  color: #005c89;
}
.sd-body__navigation .sd-btn:hover {
  background-color: #005c89;
  color: white;
}
.svc-question__content * {
  overflow-x: unset !important;
}
.sv-tagbox__item-text .sv-string-viewer {
  color: #fff;
}
.panels-title,
.map-sub-heading {
  font-weight: 600;
  font-size: 16px !important;
  color: #2c3236 !important;
}
.map-sub-heading {
  color: #6f747b !important;
}

h2.panels-title {
  margin-top: 0 !important;
  color: #6f747b !important;
}
/**************** Designer panel *****************/
.svc-question__adorner {
  background-color: white;
}

/* hide toolbar controls */
.dynamic-form-surveyjs #action-undo,
.dynamic-form-surveyjs #action-redo,
.dynamic-form-surveyjs #svd-settings,
.dynamic-form-surveyjs #deviceSelector,
.dynamic-form-surveyjs #orientationSelector,
.dynamic-form-surveyjs #showInvisible,
.dynamic-form-surveyjs #themeSwitcher {
  display: none;
}

.sd-row__question {
  white-space: wrap !important;
}

/* hide adding question types */
.svc-panel__question-type-selector {
  display: none !important;
}

/* design static panel button */
.get-gps-data-btn {
  display: flex;
  width: 356px;
  height: 40px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 24px !important;
  background-color: #005b89 !important;
  color: #fff !important;
  font-family: Overpass;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.get-gps-data {
  display: flex;
  height: 40px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 24px !important;
  background-color: #005b89 !important;
  color: #fff !important;
  font-family: Overpass;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.pick-on-map-btn {
  display: flex;
  padding: 8px 12px !important;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 24px !important;
  border: 2px solid #005c89 !important;
  background: #fff;
  color: #005c89;
  font-family: Overpass;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-top: 36px;
  line-height: 16px;
}
.svc-flex-column {
  /* max-height: 90vh;
  overflow-y: auto; */
}
/* hiding services url property of dropdown question creator */
.dynamic-form-surveyjs div[data-name="choicesByUrl"] {
  display: none !important;
}

/* hidingthe search bar on property grid sidebar */
.spg-search-editor_container {
  display: none;
}
.spg-container_search .spg-root-modern {
  height: 100% !important;
  top: 0 !important;
}

.svc-side-bar__container-content {
  /* overflow-y: unset ; */
}

/* fixing panels inputs field overflow issue */
.svc-question__content .sd-composite .sd-question__content {
  margin: 0 2px 2px 2px !important;
}

.spg-question[data-name="showAll"] .spg-checkbox__caption {
  font-weight: bold;
}

.spg-question[data-name="requiredAll"] {
  margin-top: 5px;
}
.spg-question[data-name="requiredAll"] .spg-checkbox__caption {
  font-weight: bold;
}

.svd-test-results__text.svd-light-bg-color {
  width: 100% !important;
}
.sv-list__item--selected {
  background-color: var(--primary) !important;
}
/*
  body * {
    font-family: Overpass;
  }
  .svg-icon {
    width: 12px;
    height: 12px;
  }
  
  .svg-icon path,
  .svg-icon polygon,
  .svg-icon rect {
    fill: #909090;
  }
  
  .svg-icon circle {
    stroke: #909090;
    stroke-width: 1;
  }
  
  .sd-input {
    box-shadow: none;
    border: 1px solid #bdc0c4;
  }
  .sd-input:focus {
    border-color:transparent;
  }
  .sd-input::placeholder {
    color:#9B9FA5;
  }
  .svc-toolbox {
    background-color: #F3F3F3; 
  }
  .svc-tab-designer {
    background-color: #F3F3F3 ;
  }
  .spg-panel__content{
    background-color: #F3F3F3 ;
  }
  
  
  .sd-navigation__complete-btn {
    color:var(--primary);
    border:2px solid var(--primary)
  }
  .sd-navigation__complete-btn:hover {
    background-color: var(--primary);
  }
  .sd-body.sd-body--static {
    max-width: 90%;
  }
  .sd-item__decorator {
    color: white;
  }
  .sd-rating__item--selected {
    background-color: white;
  }
  .sv-string-viewer{
    color: black;
  }
  
  
  .sd-file {
    min-height: 12vh;
  }
  
  .sd-selectbase__label {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  
  
  
  #river-condition-icon::before {
    content: \e080";
    width: 50px;
    height: 50px;
    background-color: red;
  } */
.dynamic-form-surveyjs div[data-key="TemperatureType-1"],
.dynamic-form-surveyjs div[data-key="AirTemperatureUnit-1"] {
  display: flex;
  align-items: flex-end;
}

.sd-page .sd-page__description {
  color: #0000008c !important;
}


