.Img_user_Data{
	border-radius: 50%;
	height: 35px;
	width: 35px;
	// border: 1px solid #000;
}
.svg-container:hover .arrow_icon {
	display: block;
	color: #E6E8E9;
  }
  .svg-container {
	position: relative;
  }
.rotate_icon{
	transform: rotate(180deg);
}
.add_border_bottom{
	border-bottom: 2px solid #0a7bac;
}
.arrow_icon {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	color: #E6E8E9;
  }